$(function () {
    (function allPageNotification() {
        $('.notification-box').on('click', '.btn-close', function () {
            $(this).closest('.notification-box').fadeOut(800, function () {
                $(this).remove();
            });
        });
    })();
    (function allPageScroll() {
        // if (window.addEventListener) window.addEventListener('DOMMouseScroll', wheel, false);
        // window.onmousewheel = document.onmousewheel = wheel;
        //
        // function wheel(event) {
        //     var delta = 0;
        //     if (event.wheelDelta) delta = event.wheelDelta / 120;
        //     else if (event.detail) delta = -event.detail / 3;
        //
        //     handle(delta);
        //     if (event.preventDefault) event.preventDefault();
        //     event.returnValue = false;
        // }
        //
        // function handle(delta) {
        //     var time = 1000;
        //     var distance = 700;
        //
        //     $('html, body').stop().animate({
        //         scrollTop: $(window).scrollTop() - (distance * delta)
        //     }, time );
        // }
    })();
    (function allPageRedirect() {
        // window.history.pushState("object or string", "Title", "/new-url");
    })();
    (function allPageHeader() {
        $('header .user').on('click', '.tab-header .link', function (event) {
            if (!$(this).attr('href')) {
                if (!$(this).hasClass('active')) {
                    $('header .user .tab-header .link').removeClass('active');
                    $(this).addClass('active');
                    $('header .user .dropdown-body').removeClass('active');
                    $('header .user .dropdown-body.' + $(this).data('target')).addClass('active');
                }
            }
            // event.preventDefault();
        });
    })();
    (function allPageHeight() {
        var bh = $(window).outerHeight(),
            hh = $('header').outerHeight(),
            fh = $('footer').outerHeight(),
            nh = $('nav').length ? $('nav').outerHeight() : 0,
            sh = $('section#content').outerHeight();
        if (bh > (hh + fh + sh)) {
            $('section#content > [id*=content]').css('min-height', (bh-hh-fh-nh) + 'px');
        }
        else {
        }
    })();
    (function allPageFooter() {
        if ($(window).width() < 992) {
            $('.col-right').matchHeight();
        }
    })();
    (function landingNavigation() {
        if ($('nav.type-landing').length) {
            var $target = $('nav.type-landing > div .container > ul > li');
            var $window_width = $(window).width();
            $target.mouseenter(function () {
                $(this).find('.services').attr('style', 'display:block');
                if ($(this).offset().left + $(this).find('.services').outerWidth() >= $window_width) {
                    $(this).find('.services').addClass('right');
                }
                $(this).find('.services').removeAttr('style');
                $(this).addClass('open');
            })
                .mouseleave(function () {
                    $(this).removeClass('open').find('.services').removeAttr('style').removeClass('right');
                });

            if ($(window).length >= 768) {
                var stickyMenu = document.querySelector('.js-izzi-sticky');
                var stickyMenuPosition = $(stickyMenu).position();
                var izziSticky = new IzziSticky({
                    heightValue: stickyMenuPosition.top
                });
                izziSticky.init(stickyMenu);
            }
        }
    })();
    (function landingParallax() {
        $('#search-wrapper').parallax({
            imageSrc: $('#search-wrapper').data('src'),
            speed: 0.6
        });
    })();
    (function landingFeaturedProfiles() {
        var $element = $('.featured-profiles');
        $element.find('.sliders').slick({
            slidesToShow: 3,
            slidesToScroll: 3,
            appendArrows: false
        });

        $element.on('click', '.btn-prev', function () {
            $element.find('.sliders').slick('slickPrev');
        });

        $element.on('click', '.btn-next', function () {
            $element.find('.sliders').slick('slickNext');
        });
    })();

    (function professionalIndexParallax() {
        // if($('#banner-wrapper').length) {
        //     $('#banner-wrapper').parallax({
        //         imageSrc: $('#banner-wrapper').data('src'),
        //         speed: 0.6
        //     });
        // }
    })();
    (function professionalIndexNavigation() {
        if ($('nav.type-listing').length) {
            var $target = $('nav.type-listing > div .container > ul > li');
            var $window_width = $(window).width();
            $target.mouseenter(function () {
                $(this).find('.services').attr('style', 'display:block');
                if ($(this).offset().left + $(this).find('.services').outerWidth() >= $window_width) {
                    $(this).find('.services').addClass('right');
                }
                $(this).find('.services').removeAttr('style');
                $(this).addClass('open');
            })
                .mouseleave(function () {
                    $(this).removeClass('open').find('.services').removeAttr('style').removeClass('right');
                });

            if ($('.js-izzi-sticky').length) {
                var stickyMenu = document.querySelector('.js-izzi-sticky');
                var stickyMenuPosition = $(stickyMenu).position();
                var izziSticky = new IzziSticky({
                    heightValue: stickyMenuPosition.top
                });
                izziSticky.init(stickyMenu);
            }

            if ($('#profile-header').length) {
                var stickyHeader = document.querySelector('#profile-header');
                var izziStickyHeader = new IzziSticky({
                    heightValue: stickyHeader.offsetHeight
                });
                izziStickyHeader.init(stickyHeader);
            }
        }
    })();

    (function showMoreButton() {
        $.fn.matchHeight._maintainScroll = true;
        $(document).on('click', '.paragraph .showMore', function (event) {
            event.preventDefault();
            var wrapper = $(this).closest('.paragraph');
            wrapper.addClass('hidden');
            wrapper.next('.to_extend').addClass('extended');
            $.fn.matchHeight._update();
        });
        $(document).on('click', '.paragraph .showLess', function (event) {
            event.preventDefault();
            var wrapper = $(this).closest('.paragraph').parent().find('.collapsed');
            wrapper.removeClass('hidden');
            wrapper.next('.to_extend').removeClass('extended');
            $.fn.matchHeight._update();
        });
    })();
    (function allPageFooterLanguage() {
        $('footer .footer-top .languages').click(function() {
            $(this).find('.options').addClass('active');
        });
        $(document).click(function(e)
        {
            var container = $('footer .footer-top .languages');

            // if the target of the click isn't the container nor a descendant of the container
            if (!container.is(e.target) && container.has(e.target).length === 0)
            {
                container.find('.options').removeClass('active');
            }
        });
    })();
});

(function(window){
    window.htmlentities = {
        /**
         * Converts a string to its html characters completely.
         *
         * @param {String} str String with unescaped HTML characters
         **/
        encode : function(str) {
            var buf = [];

            for (var i=str.length-1;i>=0;i--) {
                buf.unshift(['&#', str[i].charCodeAt(), ';'].join(''));
            }

            return buf.join('');
        },
        /**
         * Converts an html characterSet into its original character.
         *
         * @param {String} str htmlSet entities
         **/
        decode : function(str) {
            return str.replace(/&#(\d+);/g, function(match, dec) {
                return String.fromCharCode(dec);
            });
        }
    };
})(window);

(function allPageLoadingBar() {

    var nanobar = new Nanobar();
    nanobar.go(20);
    $(window).load(function () {
        nanobar.go(100);
    });

    // $(document).ajaxSend(function () {
    //     nanobar.go(15);
    // }).ajaxComplete(function () {
    //     nanobar.go(100);
    // });
})();

Array.prototype.remove = function () {
    var what, a = arguments, L = a.length, ax;
    while (L && this.length) {
        what = a[--L];
        while ((ax = this.indexOf(what)) !== -1) {
            this.splice(ax, 1);
        }
    }
    return this;
};

Number.prototype.formatMoney = function (c, d, t) {
    var n = this,
        c = isNaN(c = Math.abs(c)) ? 2 : c,
        d = d == undefined ? "." : d,
        t = t == undefined ? "," : t,
        s = n < 0 ? "-" : "",
        i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
        j = (j = i.length) > 3 ? j % 3 : 0;
    return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
};

function boldString(str, find) {
    var re = new RegExp(find, 'g');
    return str.replace(re, '<b>' + find + '</b>');
}

if (!String.prototype.trim) {
    (function () {
        // Make sure we trim BOM and NBSP
        var rtrim = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g;
        String.prototype.trim = function () {
            return this.replace(rtrim, '');
        };
    })();
}

// hack for iPhone 7.0.3 multiselects bug
if(navigator.userAgent.match(/iPhone/i)) {
    $('select[multiple]').each(function(){
        var select = $(this).on({
            "focusout": function(){
                var values = select.val() || [];
                setTimeout(function(){
                    select.val(values.length ? values : ['']).change();
                }, 1000);
            }
        });
        var firstOption = '<option value="" disabled="disabled"';
        firstOption += (select.val() || []).length > 0 ? '' : ' selected="selected"';
        firstOption += '>« Select ' + (select.attr('title') || 'Options') + ' »';
        firstOption += '</option>';
        select.prepend(firstOption);
    });
}

function ToSeoUrl(url) {

    // make the url lowercase
    var encodedUrl = url.toString().toLowerCase();

    // replace & with and
    encodedUrl = encodedUrl.split(/\&+/).join("-and-")

    // remove invalid characters
    encodedUrl.replace(/[\|&;\$%@'"<>\(\)\+,]/g, "-");
    // encodedUrl = encodedUrl.split(/[^a-z0-9]/).join("-");

    // remove duplicates
    encodedUrl = encodedUrl.split(/-+/).join("-");

    // trim leading & trailing characters
    encodedUrl = encodedUrl.trim('-');

    return encodedUrl;
}

function checkIfEmailInString(text) {
    var pattern = /([a-zA-Z0-9._+-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi;
    return text.match(pattern);
}

function checkIfPhoneInString(text) {
    text = text.replace('-','');
    text = text.replace('+','');
    text = text.replace(/ /g,'');
    var pattern = /(\d{7,})/;
    return pattern.test(text);
}